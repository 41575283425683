import React, { useEffect, useState } from 'react';
import { Footer } from '../components/footer/Footer';
import { FixedMobileMenu } from '../components/fixedMobileMenu/FixedMobileMenu';
import { Navbar } from '../components/navbar/Navbar';
import { NavbarMenu } from '../components/navbarMenu/NavbarMenu';
import { DeviceEnum } from '../utils/enums';
import { SEO } from '../components/seo/Seo';
import { usePageContext } from '../hooks/context/usePageContext';
import * as styles from './layout.module.scss';
import { ButtonCalendly } from '../components/buttonCalendly/ButtonCalendly';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { originalPath } = usePageContext();

  // useEffect(() => {
  //   document.body.style.overflow = open ? 'hidden' : 'auto';
  // }, [open]);

  /** see _mixins */
  useEffect(() => {
    const scrollbarWidth =
      typeof window !== `undefined` ? window.innerWidth - document.body.clientWidth : 0;
    document.body.style.setProperty('--viewportWidth', `calc(100vw - ${scrollbarWidth}px)`);
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [originalPath]);

  return (
    <div className={styles.wrapper}>
      <SEO />
      <header className={styles.pageHeader}>
        <Navbar />
      </header>
      <main>
        {children}
        <ButtonCalendly />
      </main>
      <div className={styles.footer}>
        <Footer />
      </div>
      <NavbarMenu device={DeviceEnum.mobile} open={open} />
      <FixedMobileMenu open={open} setOpen={setOpen} />
      <div className={styles.calendly} id="__calendly" />
    </div>
  );
};
